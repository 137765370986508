import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultFlexAlignDirective } from './flex-align';
import { DefaultFlexOffsetDirective } from './custom-flex-offset';
import { DefaultFlexOrderDirective } from './custom-flex-order';
import { DefaultFlexDirective } from './custom-flex';
import { DefaultLayoutGapDirective } from './custom-layout-gap';
import { DefaultLayoutAlignDirective } from './custom-layout-align';
import { DefaultLayoutDirective } from './custom-layout';
import { DefaultClassDirective } from './ng-class';
import { DefaultShowHideDirective } from './show-hide';
import { DefaultImgSrcDirectiveNew } from './img-src';
import { DefaultStyleDirective } from './ng-style';






@NgModule({
  declarations: [
    DefaultLayoutDirective,
    DefaultLayoutGapDirective,
    DefaultLayoutAlignDirective,
    DefaultFlexOrderDirective,
    DefaultFlexOffsetDirective,
    DefaultFlexAlignDirective,
    DefaultFlexDirective,
    DefaultShowHideDirective,
    DefaultClassDirective,
    DefaultStyleDirective,
    DefaultImgSrcDirectiveNew,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DefaultLayoutDirective,
    DefaultLayoutGapDirective,
    DefaultLayoutAlignDirective,
    DefaultFlexOrderDirective,
    DefaultFlexOffsetDirective,
    DefaultFlexAlignDirective,
    DefaultFlexDirective,
    DefaultShowHideDirective,
    DefaultClassDirective,
    DefaultStyleDirective,
    DefaultImgSrcDirectiveNew

  ]
})
export class CustomFlexLayoutModule {
    static withConfig: any;
}
