<div *ngIf="display">
    <div *ngIf="url!='/home'" class="header">


        <mat-toolbar style="position: fixed;" fxHide fxShow.gt-md class="header-v2 app-toolbar primary"
            fxLayoutAlign="space-between center">
            <mat-toolbar-row style="height: 100%;" fxLayoutAlign="space-between center" class="theme-container">


                <div fxLayout="row" fxLayoutGap="10px" fxFlex fxLayoutAlign="start center">

                    <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row">
                        <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                            [categoryArray]="item"></calsoft-Menu>
                    </mat-toolbar-row>


                    <section fxLayoutAlign="center center">
                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                    </section>


                </div>



                <div *ngIf="data.disableSearchInHeader==0" fxLayout="row" fxFlex="39" fxLayoutGap="10px"
                    fxLayoutAlign="end center">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer; color:white" fxLayoutAlign="center center"
                            *ngIf="!displaySearchBar" (click)="showSearchBar()">search</mat-icon>
                    </div>


                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>

                </div>
                <div fxFlex="2">

                </div>

                <div fxLayoutAlign="end center" *ngIf="url != '/cart'">

                    <div *ngIf="data.disableWishlistHeader==0">
                        <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after"
                            matBadgeColor="accent">
                            <i class="material-icons notranslate">
                                favorite_border
                            </i>
                        </button>
                    </div>

                    <calsoft-cart-icon [color]="false"></calsoft-cart-icon>

                </div>

                <!-- <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
        <div style="color: #ffffff" fxLayout="column">
            <small>Hello, Sign in</small>
            <span>Account & Lists</span>
        </div>
    </button>

    <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
    </calsoft-HeaderUserProfileDropdown> -->



            </mat-toolbar-row>

        </mat-toolbar>
        <mat-toolbar fxHide fxShow.gt-md>



            <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center"
                style=" background: #fff;margin-top: 90px;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: -webkit-fill-available"
                class="header-v2-bottom main-menu-wrap theme-container">



            </mat-toolbar-row>
        </mat-toolbar>
    </div>


    <div *ngIf="url=='/home'" class="header">
        <div class="header-wrapper" fxLayoutGap="10px" fxLayout="row">
            <div fxLayout="row" fxLayoutGap="10px" fxFlex fxLayoutAlign="start center">

                <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row">
                    <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                        [categoryArray]="item"></calsoft-Menu>
                </mat-toolbar-row>


                <section fxLayoutAlign="center center">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                </section>


            </div>
            <div fxLayout="row" fxFlex="45" fxLayoutAlign="end center">

                <div *ngIf="data.disableSearchInHeader==0" fxLayout="row" fxFlex fxLayoutGap="10px"
                    fxLayoutAlign="end center">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer; color: white;" fxLayoutAlign="center center"
                            *ngIf="!displaySearchBar" (click)="showSearchBar()">search</mat-icon>
                    </div>


                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>

                </div>

                <div fxFlex="20px">

                </div>

                <div *ngIf="url!='/account/wishlist'">
                    <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after"
                        matBadgeColor="accent">
                        <i class="material-icons notranslate">
                            favorite_border
                        </i>
                    </button>
                </div>

                <calsoft-cart-icon [color]="false"></calsoft-cart-icon>

                <div *ngIf="data.disableLoginHeader==0">
                    <div *ngIf="!userLoggedIn" class="_sadh" (click)="SignIn('login')" fxLayoutAlign="start center">
                        <button mat-button class="w-color" *ngIf="!userLoggedIn">
                            <mat-icon style="color: white;">person</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data" class="headerUser" *ngIf="userLoggedIn">
                </calsoft-HeaderUserProfileDropdown>
                </div>

            </div>

        </div>

        <mat-toolbar fxHide fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
            fxLayoutAlign="space-around center" style="height: auto; z-index: 1000;">

            <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;">

                <div fxLayout="row" fxLayoutGap="10px" fxFlex fxLayoutAlign="start center">

                    <mat-toolbar-row fxHide fxShow.gt-sm class="container" fxLayout="row" fxLayout.xs="row">
                        <calsoft-Menu [color]="true" [data]="data" [type]="'one'" style="color: #000000;" class="menu"
                            [categoryArray]="item"></calsoft-Menu>
                    </mat-toolbar-row>


                    <section fxLayoutAlign="center center">
                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                    </section>


                </div>



                <div *ngIf="data.disableSearchInHeader==0" fxLayout="row" fxFlex="38" fxLayoutGap="10px"
                    fxLayoutAlign="end center">
                    <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                        <mat-icon style=" cursor: pointer; color:white" fxLayoutAlign="center center"
                            *ngIf="!displaySearchBar" (click)="showSearchBar()">search</mat-icon>
                    </div>


                    <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                        fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="300px">
                        <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                            [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>

                </div>
                <div fxFlex="2">

                </div>

                <div fxLayoutAlign="end center" *ngIf="url != '/cart'">

                    <button style="color: white;" (click)="wishlist()" mat-icon-button matBadgePosition="after"
                        matBadgeColor="accent">
                        <i class="material-icons notranslate">
                            favorite_border
                        </i>
                    </button>

                    <calsoft-cart-icon [color]="false"></calsoft-cart-icon>

                </div>



            </mat-toolbar-row>
        </mat-toolbar>

    </div>





</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>



</div>
<!-- Mobile View  end -->