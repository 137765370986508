<div class="header-main-wrapper" *ngIf="menutype != 1">
  <nav class="navigation collapse navbar-collapse pull-right" style="height: 100px;">
    <ul class="nav-links nav navbar-nav">
      <li *ngFor="let item of categoryArray;let i =index " [class.dropdown]="item.subcategories.length > 0" [class.active]="i==highlightedindex">
        <a  [style.fontSize]="data.categoryFontSize" (click)="goToCategory(item,i)" class="main-menu" style="color: black;">{{ item.categoryName  | characterPipe }}
          <span  [style.color]="data.categoryColor" [style.fontSize]="data.categoryFontSize" *ngIf="item.subcategories.length > 0" class="fa fa-angle-down icons-dropdown"></span>
        </a>
        <ul *ngIf="item.subcategories.length > 0" class="dropdown-menu nwsipl-dropdown-menu-1">
          <li *ngFor="let child of item.subcategories">
            <a style="color: black;" (click)="goToCategory(child,i)" [style.fontSize]="data.categoryFontSize" class="link-page">{{ child.categoryName  | characterPipe }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>


<div class="header-main-wrapper" *ngIf="menutype == 1">
  <button class="menu-icon" (click)="toggleMenu()">
    <i class="fa fa-bars"></i>
  </button>

  <nav class="navigation" [class.open]="menuOpen">
    <div class="nav-header" fxLayout="row" fxLayoutAlign="end center">
      <button class="close-menu" (click)="toggleMenu()">
        <i class="fa fa-times"></i>
      </button>
    </div>

    <ul class="nav-links">
      <li *ngFor="let item of categoryArray; let i = index" 
          [class.dropdown]="item.subcategories.length > 0" 
          [class.active]="i == highlightedindex"
          [class.open]="subMenuOpen[i]">

        <!-- Anchor tag now calls goToCategory(item) -->
        <a [style.fontSize]="data.categoryFontSize" 
           (click)="goToCategory(item); toggleSubMenu(i)" 
           class="main-menu" 
           style="color: black;">
          {{ item.categoryName | characterPipe }}
          <span *ngIf="item.subcategories.length > 0" 
                class="fa icons-dropdown" 
                [ngClass]="{'fa-angle-down': !subMenuOpen[i], 'fa-angle-up': subMenuOpen[i]}" 
                [style.color]="data.categoryColor" 
                [style.fontSize]="data.categoryFontSize">
          </span>
        </a>

        <ul *ngIf="item.subcategories.length > 0 && subMenuOpen[i]" 
            class="dropdown-menu nwsipl-dropdown-menu-1">
          <li *ngFor="let child of item.subcategories">
            <a style="color: black;" 
               (click)="goToCategory(child, i)" 
               [style.fontSize]="data.categoryFontSize" 
               class="link-page">
              {{ child.categoryName | characterPipe }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>



