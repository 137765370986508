<div *ngIf="display">
    <header class="header header-6 theme-container" fxHide fxShow.gt-md style="position: fixed;z-index: 10;">
        <!-- <div class="header-top" style="background-color: #F5F5F5;">
            <div [class]="containerClass">
                <div fxFlex="5">

                </div>
                <div class="header-right">
                    <div style="cursor:pointer ;font-size: 12px;" fxLayoutAlign="center center">
                        <div *ngIf="data.disableLoginHeader==0">
                        <div class="login padding-10" *ngIf="!userLoggedIn" (click)="SignIn('login')">
                            <p style="font-size: 14px;">Login</p>
                        </div>
                        </div>

                        <calsoft-HeaderUserProfileDropdown [data]="data" [color]="'white'"
                            *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="header-middle" >
            <div class="containerCss">
                    <div fxFlex="3"></div>
                    <div fxFlex="10">                    
                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                    </div>
                    <div fxFlex="4"></div>

                    <div fxFlex="60" fxLayoutAlign="center center">                    
                        <div *ngIf="showData">
                        <!-- <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="list1"></calsoft-Menu> -->
                            <h3 style="font-size: 27px;margin-bottom: 0px;">{{data.headerNotificationContent}}</h3>
                        </div>
                    </div>
                    <div fxFlex="10" fxLayoutAlign="center center" *ngIf="showData">                        
                        
                        <mat-icon  class="icon-css" (click)="gotoCall()">call</mat-icon>
                        <div *ngIf="data.disableLoginHeader==0">
                                <mat-icon class="icon-css" *ngIf="!userLoggedIn" (click)="SignIn('login')">person</mat-icon>
                        </div>
                        <div *ngIf="data.disableLoginHeader==0">
                          <calsoft-HeaderUserProfileDropdown [data]="data" [color]="'white'"
                        *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                        </div>
                    </div>

                    <div fxFlex="10" *ngIf="!showData"></div>    
                    <div fxFlex="10" fxLayoutAlign="center center">
                            <button class="btncss" mat-raised-button  (click)="open_popup()">Get started</button>
                        </div>

                    <div fxFlex="3"></div>
            </div>
        </div>

    </header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==3">
        <calsoft-mobile-header4  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header4>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>


</div>