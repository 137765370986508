<div *ngIf="display">
<mat-toolbar class="primary" fxHide  fxShow.gt-md>
  <mat-toolbar-row class="primary theme-container" style="padding: 0px 10px;color: #ffffff;height: 40px"   fxLayout="row"
      fxLayoutAlign="end center">
      <calsoft-call [data]="data"></calsoft-call>
      <div *ngIf="languageSelection==1">
          <calsoft-language  style="color: #ffffff"></calsoft-language>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist' && userLoggedIn">
              <mat-icon>favorite_border</mat-icon>
              Wishlist
          </button>
          <div *ngIf="data.disableLoginHeader==0">
          <button *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
              Login/Signup
          </button>
          </div>
          <div *ngIf="data.disableLoginHeader==0">
          <calsoft-HeaderUserProfileDropdown [data]="data"  *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
          </div>
      </div>
  </mat-toolbar-row>
  <mat-toolbar-row style="background: yellow; height: 100%;" class="theme-container" fxLayout="row" fxLayoutAlign="space-between center">
      <section>
          <calsoft-logo [sitename]="data.siteName"  [type]="'light'"></calsoft-logo>

      </section>
      <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
          fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="500px">
          <calsoft-search [searchbarborderRadius]="'18px'"></calsoft-search>

      </div>
      <div *ngIf="url != '/cart'">
          <calsoft-cart-icon [color]="true"></calsoft-cart-icon>
        
      </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="theme-container" style="padding: 0px ;color: #000000;height: -webkit-fill-available;background: #ffff" fxLayout="row" fxLayoutAlign="center center">
      <calsoft-Menu [color]="false"  [data]="data"  [type]="'one'" style="color: #000000;z-index: 1000;" class="menu" [categoryArray]="item"></calsoft-Menu>

  </mat-toolbar-row>

</mat-toolbar>

<mat-toolbar fxHide fxShow.gt-md fxShow.md  class="calsoft-fixed-header primary" fxLayout="row"
  fxLayoutAlign="space-between center" style="  z-index: 1000;">
  <mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
  <section>
      <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

  </section>
  <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
      fxFlex.gt-sm="500px">
      <calsoft-search></calsoft-search>

  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      
      <button style="color:#ffffff" *ngIf="!userLoggedIn" (click)="SignIn('login')" mat-button>
          Login/Signup
      </button>
      <div *ngIf="data.disableLoginHeader==0">
      <calsoft-HeaderUserProfileDropdown [type]="scroll" [data]="data" class="headerUser" *ngIf="userLoggedIn">
      </calsoft-HeaderUserProfileDropdown>
      </div>

      <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
  </div>
  </mat-toolbar-row>
</mat-toolbar>
</div>

<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
        </div>

        <div *ngIf="mobileHeaderType==5">
            <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
            </calsoft-mobile-header6>
        </div>
        <div *ngIf="mobileHeaderType==6">
            <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
        
            </calsoft-mobile-header7>
        </div>
    
</div>

<!-- <calsoft-mobile-header-fixed [url]="url" [userLoggedIn]="userLoggedIn">

</calsoft-mobile-header-fixed> -->
