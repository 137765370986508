<div *ngIf="display">
    <mat-toolbar style="position: fixed;" fxHide fxShow.gt-md class="header-v2 app-toolbar bg"
        fxLayoutAlign="space-between center">
        <mat-toolbar-row style="height: 100%;" fxLayoutAlign="space-between center" class="theme-container">

            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <section class="padding-10">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>

                <div *ngIf="data.displayPincode==1" fxLayoutGap="10px" 
                    fxLayoutAlign="center center">
                    <button mat-flat-button (click)="availablePostalCode()">Select Pincode
                        <mat-icon>location_on</mat-icon>
                    </button>
                </div>

            </div>



            <div *ngIf="data.disableSearchInHeader==0" fxLayoutAlign="center center" class="search-bar" fxLayoutGap="10px" fxHide fxShow.gt-sm fxShow.gt-sm
                fxLayout="row" fxFlex fxFlex.gt-sm="500px">
                <calsoft-search [scroll]="false" [searchbarborderRadius]="'10px'"
                    fxLayoutAlign="center center"></calsoft-search>
            </div>

            <div fxHide fxShow.gt-sm class="list-flex user-tool">

                <calsoft-cart-icon [color]="true"></calsoft-cart-icon>


                <div *ngIf="!userLoggedIn" (click)="SignIn('login')" fxLayoutAlign="end center">
                    <div *ngIf="data.disableLoginHeader==0">
                    <button mat-button *ngIf="!userLoggedIn">
                        <mat-icon style="color: black;">person</mat-icon>
                    </button>
                    </div>
                </div>
                <div *ngIf="data.disableLoginHeader==0">
                <calsoft-HeaderUserProfileDropdown [data]="data"
                    *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                    </div>
            </div>
        </mat-toolbar-row>

    </mat-toolbar>
    <mat-toolbar fxHide fxShow.gt-md>

        <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center"
            style=" background: #fff;margin-top: 90px;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: 50px">

            <calsoft-Menu [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>

        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
    </div>

    <div *ngIf="mobileHeaderType==5">
        <calsoft-mobile-header6 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header6>
    </div>
    <div *ngIf="mobileHeaderType==6">
        <calsoft-mobile-header7 [item]="item" [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header7>
    </div>

        

</div>
<!-- Mobile View  end -->